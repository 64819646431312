.search-bar {
    padding: 5px;
    padding-left: 30px;
    border-radius: 10px;
    width: inherit;
}

.search-bar-container {
    margin: 0 auto;
    margin-top: 5px;
    width: min(300px, 100% - 40px);
}

.search-bar-container img {
    position: absolute;
    padding: 5px;

    opacity: 0.3;
    width: 20px;
}