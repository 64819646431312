/* Add space at bottom */
#content {
    padding-bottom: 50px;
}

#social-container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 0px 10px 0px 0px;
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: rgba(206, 206, 206, 0.8);
    z-index: 10;
}

#social-container img {
    padding: 7px;
    height: 20px;
    width: auto;
    transition: height 0.1s;
}
