.news-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0px;
    margin-top: 5px;
    overflow: hidden;
}

.news-item {
    margin: 5px;
    padding: 4px;
    width: calc(100% - 20px);
    white-space: pre-line;
    border: 2px solid rgb(214, 214, 214);
    border-left: 5px solid  rgb(165, 165, 165);
    transition: border-color 0.5s;
    border-radius: 5px;

    float: left;
    background-color:rgb(250, 250, 250);
/*     text-transform: lowercase; */
}

.news-item-selected {
    border-color:  rgb(77, 160, 149);
}

.news-item-header-container {
    overflow: hidden;
    padding-left: 10px;
}

.news-subtitle {
    color: var(--dark-grey);
    font-weight: normal;
    font-size: 0.9em;
}


.news-item-date {
    color: var(--dark-grey);
    font-size: 0.8em;
}

.news-item-tag {
    font-size: 0.8em;
    float: right;
    margin: 2px;
    padding: 5px;

    color: white;
    border-radius: 5px;
    background-color: rgb(77, 160, 149, 0.7);
}


/* General list styles */
.toggle-details {
    display: none;
}

.show-details-button img {
    float: right;
    border: none;
    background: transparent;
    outline: 0;
}

.show-details-button * {
    cursor: pointer;
    transition: opacity 0.2s;
}

.show-details-button:hover * {
    opacity: 1;
}


/* Default for internal content */


.news-item-content {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 10px;
    color: black;
    font-size: 1em;
    line-height: 1.4;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-rendering: optimizeLegibility;
    max-width: 800px;
    margin: 0 auto;
    background-color: rgb(230, 230, 230);
    overflow: hidden;
}

.news-item-content a {
    color: rgb(17, 82, 204);
    opacity: 0.7;
    transition: opacity 0.3s;
}

.news-item-content a:hover {
    opacity: 1;
}