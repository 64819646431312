#about-page {
    margin: 0 auto;
    overflow: hidden;
    max-width: 800px;
    display: none;
}

.bio-container {
    border-left: 5px solid;
    border-right: 5px solid;
    border-color: #9c0000;
    padding: 10px;
    width: calc(100% - 30px);
    min-height: calc(100vh - 60px);
    float: left;
    border-radius: 0px 0px 5px 5px;

    transition: background-color 1s;
}

.bio-container.light {
    background-color: rgba(233, 233, 233, 0.6); 
}

.bio-container.dark {
    background-color: rgb(0, 0, 0, 0.9);
}

.bio-image-container {
    max-width: 800px;
    padding-bottom: 62.5%;
    margin: 0;
    position: relative;
    background: rgb(124, 0, 0);
}

.bio-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}




.about-section-content {
    font-size: 0.9em;
    /* display: none; */
/*     color: white; */
    /* overflow: hidden; */
}


/* .about-section:nth-child(2) {
    border-top: 2px solid rgb(255, 255, 255, 0.5);
}
 */
.about-section {
    position: relative;
    padding: 10px;
    border-radius: 0px;
    /* border-bottom: 2px solid; */

    transition: border-color 1s;
}


.about-section.light {
    border-color: rgb(112, 112, 112);
}

.about-section.dark {
    border-color: rgb(255, 255, 255, 0.5);
}

.about-section * {
    transition: color 1s;
}

.about-section.light * {
    color: var(--dark-grey);
}

.about-section.dark * {
    color: white;
}


.about-section button {
    width: 100%;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1.2em;
    opacity: 0.5;
    transition: opacity 0.2s;
}

.about-section button:hover {
    opacity: 1;
}

.click-hide {
    pointer-events:none;
    position: absolute;
    right: 10px;
    font-size: 0.5em;
    top: 12px;
    
    transition: filter 1s;
}


.click-hide.light {
    filter: none;
}

.click-hide.dark {
    filter: invert();
}

.about-section .hover-anchor, .about-section .hover-anchor em {
    font-weight: 700;
    color:rgb(8, 199, 173) !important;
}



.checkout-container {
    margin: 0 auto;
    text-transform: lowercase;
    padding: 10px;
    text-align: center;
    max-width: 400px;
    font-size: 0.9em;
}

.checkout-description {
    color: grey;
}

.checkout-container hr {
    max-width: 75%;
}

.birth-year {
  font-family: 'Times New Roman', Times, serif;
  border: 5px dashed red;
  text-align: center;
  font-size: 30vw;
  width: 95%;
  animation: colorRotate .5s linear 0s infinite;
}

@keyframes colorRotate {
  from {
    color: #6666ff;
  }
  10% {
    color: #0099ff;
  }
  50% {
    color: #00ff00;
  }
  75% {
    color: #ff3399;
  }
  100% {
    color: #6666ff;
  }
}