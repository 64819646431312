.work-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0px;
    margin-top: 5px;
    overflow: hidden;
}

.work-header-container {
    opacity: 0.6;
    padding: 5px;
}

.work-header-container * {
    margin: 5px 0px;
}

.work-year {
    color:  var(--dark-grey);
    font-weight: normal;
}

.work-description {
    color: var(--dark-grey);
    font-size: 0.9em;
    max-width: 1000px;
}

.work {
    margin: 2px;
    padding: 5px;
    width: calc(100% - 20px);
    white-space: pre-line;
    border-left: 5px solid rgb(83, 112, 156);
    transition: border-color 0.5s;
    border-radius: 5px;

    display: inline;
    list-style-type: none;
    float: left;
    background-color:rgba(187, 187, 187, 0.6);
}

.work-selected {
    border-color: rgb(41, 167, 41);
}

.work-right-container {
    padding-left: 10px;
    display: inline;
}

.work-cover {
    background: white;
    width: 200px;
    float: left;
    margin: 5px;
    margin-right: 15px;
}

.work-detail-container * {
    margin: 5px 0px;
}

.work-detail-container span {
    max-width: 200px;
    margin-right: 30px;
    float: left;
}

.purchase-button {
    color: white;
    background-color: rgb(41, 167, 41);
}

.button-cls {
  float: right;
  margin: 10px;
  border: none;
  border-radius: 5px;
  padding: 10px;
  opacity: 0.5;
}

.button-cls:hover {
    opacity: 1;
    cursor:pointer
}

.work-detail-container p {
    color: var(--dark-grey);
    font-size: 0.9em;
}

.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: calc(max(min(400px, 100%), min(50%, 600px)));
}

.work audio {
    float:right;
    margin: 10px;
}


.toggle-details {
    display: none;
}

.show-details-button img {
    float: right;
    border: none;
    background: transparent;
    outline: 0;
}

.show-details-button * {
    cursor: pointer;
    transition: opacity 0.2s;
}

.show-details-button:hover * {
    opacity: 1;
}

.work-description a {
  color: rgb(0, 46, 145) !important;
  opacity: 0.7 !important;
  transition: opacity 0.2s;
}

.work-description a:hover {
  opacity: 1 !important;
}

.video-button {
  color: white;
  background-color: rgb(176, 22, 22);
}