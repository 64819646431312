.loader-container {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 10px);
}

.mouse-disabled {
    pointer-events: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.loader {
    padding: 10px;
    display: inline-block;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    opacity: 0.5;
    transform: scale(0);
    -webkit-transform: scale(0);
    -webkit-animation: pulse 1.5s linear infinite;
    animation: pulse 1.5s linear infinite;
}

.loader:nth-child(1) {
    background-color: rgb(0, 119, 255);
    animation-delay: 0s 
}
.loader:nth-child(2) { 
    background-color: rgb(0, 174, 255);
    animation-delay: 0.25s 
}
.loader:nth-child(3) { 
    background-color: rgb(0, 247, 255);
    animation-delay: 0.5s 
}
.loader:nth-child(4) { 
    background-color: rgb(0, 255, 157);
    animation-delay: 0.75s 
}
.loader:nth-child(5) { 
    background-color: rgb(0, 255, 64);
    animation-delay: 1s; 
}

@-webkit-keyframes pulse {
    0% { -webkit-transform: scale(0); }
    40% { -webkit-transform: scale(1); }
    80% { -webkit-transform: scale(0); }
}
  
@keyframes pulse {
    0% { transform: scale(0); }
    40% { transform: scale(1); }
    80% { transform: scale(0); }
}