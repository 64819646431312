/* Make space for navbar */
#content {
    padding-top: 40px;
}

#navbar {
    z-index: 5;
    overflow: hidden;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 0px;
    width: 100%;
    opacity: 1;
    background: rgb(102, 102, 102);
}

#navbar-pages {
    z-index: 10;
}

#navbar-pages a {
    cursor: pointer;
    float: right;
    padding: 10px;
    color: #ffffff;
    opacity: 0.6;
    transition: opacity 0.2s;
}

#navbar-pages a:hover {
    opacity: 1;
}

#navbar img {
    position: absolute;
    display: none;

    height: 35px;
    padding-left: 10px;
    filter: invert(1);
}

#home-icon {
    padding: 8px;
    height: 25px !important;
    opacity: 0.7
}

#nav-title {
    display: none;
    position: absolute;
    left: 0px;
    padding: 10px;
    padding-left: 0px;
    width: 100%;
    text-align: center;
    color: white;
    z-index: -1;
}

.navbar-selected {
    font-weight: bolder;
    opacity: 1 !important;
    background: rgb(73, 73, 73);
    border-bottom: 4px solid rgb(255, 64, 0) !important;
}