.projects-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-container {
    padding: 0px;
    overflow: hidden;
}

.project {
    margin: 2px;
    padding: 0px;
    width: 200px;
    text-align: center;
    border-top: 4px solid rgb(62, 163, 84);
    border-radius: 5px;

    display: inline;
    list-style-type: none;
    float: left;
    background-color:white;
}

.project p, .project h3 {
    margin: 5px;
    opacity: 0.6;
    transition: opacity 0.2s;
}

.project-image {
    background-color: white;
    width: 200px;
}

.project h3 {
    display: inline;
}

.project a {
    color: black;
}

.project a:hover * {
    opacity: 1;
}

.project-icon-container {
    padding-left: 5px;
}

.project .transparent-icon {
    padding: 5px;
    float: left;
}