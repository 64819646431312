.text {
    padding: 20px;
    margin: 0px;
    color: white;
    position: relative;
    background-color: rgba(0, 0, 0, 0.8);
}

.text p a {
    color: rgb(91, 255, 255);
}

.main {
    position: relative;
    overflow: hidden;
}

.text p strong, .text p a {
    font-weight: 500;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 1100px;
}