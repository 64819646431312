/* Global CSS variables */
:root {
    --dark-grey: rgb(90, 90, 90);
}

a {
    text-decoration: none;
    color: black;
}

body {
    height: 100%;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.transparent-icon {
    width: 20px;
    opacity: 0.5;
    transition: opacity 0.1s;
}

.transparent-icon:hover {
    opacity: 1;
}


.hover-anchor {
    color: rgb(17, 82, 204);
    opacity: 0.7;
    transition: opacity 0.3s;
}

.hover-anchor:hover {
    opacity: 1;
}

/* Background image */
#background {
    background: white top left;
    opacity: 0.3;
    background-size: 800px;
    background-repeat:repeat;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    z-index: -1;
}

#canvas {
    z-index: -1;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}